import store from '../../assets/images/svg/store.svg'
import storeActive from '../../assets/images/svg/store-active.svg'
import category from '../../assets/images/svg/category.svg'
import categoryActive from '../../assets/images/svg/category-active.svg'
import offers from '../../assets/images/svg/offers.svg'
import offersActive from '../../assets/images/svg/offers-active.svg'
import mediahub from '../../assets/images/svg/media-hub.svg'
import mediahubActive from '../../assets/images/svg/media-hub-active.svg'
import deals from '../../assets/images/svg/deals.svg'
import dealsActive from '../../assets/images/svg/deals-active.svg'
import adplacement from '../../assets/images/svg/ad-placement.svg'
import adplacementActive from '../../assets/images/svg/ad-placement-active.svg'
import subscription from '../../assets/images/svg/subscription.svg'
import subscriptionActive from '../../assets/images/svg/subscription-active.svg'
import help from '../../assets/images/svg/help.svg'
import helpActive from '../../assets/images/svg/help-active.svg'
import reports from '../../assets/images/svg/reports.svg'
import reportsActive from '../../assets/images/svg/reports-active.svg'
import appsettings from '../../assets/images/svg/app-settings.svg'
import appsettingsActive from '../../assets/images/svg/app-settings-active.svg'
import user from '../../assets/images/svg/user.svg'
import userActive from '../../assets/images/svg/user-active.svg'
import ecommerceActive from '../../assets/images/svg/ecommerce-active.svg'
import ecommerce from '../../assets/images/svg/ecommerce.svg'
import ContactUs from '../../assets/images/svg/contact-us.svg'
import ContactUsActive from '../../assets/images/svg/contact-us-active.svg'
import Product from '../../assets/images/svg/product.svg'
import ProductActive from '../../assets/images/svg/product-active.svg'
import Comment from '../../assets/images/svg/comment.svg'
import CommentActive from '../../assets/images/svg/comment-active.svg'
import ActivityActive from '../../assets/images/svg/activity-active.svg'
import Activity from '../../assets/images/svg/activity.svg'
import Suggestion from '../../assets/images/svg/suggestion.svg'
import SuggestionActive from '../../assets/images/svg/suggestion-active.svg'
import Cashback from '../../assets/images/svg/cashback.svg'
import CashbackActive from '../../assets/images/svg/cashback-active.svg'
import ReportedCoupons from '../../assets/images/svg/reportedcoupons.svg'
import ReportedCouponsActive from '../../assets/images/svg/reportedcoupons-active.svg'
import Ranking from '../../assets/images/svg/ranking.svg'
import RankingActive from '../../assets/images/svg/ranking-active.svg'
import Challenge from '../../assets/images/svg/challenge.svg'
import ChallengeActive from '../../assets/images/svg/challenge-active.svg'



export default [
  {
    title: 'Client & Stores',
    route: 'clients-and-stores',
    // icon: 'mdi-google-my-business',
    icon: store,
    iconActive: storeActive,
  },
  {
    title: 'Monetization',
    route: 'Monetization',
    // icon: 'mdi-google-my-business',
    icon: Activity,
    iconActive: ActivityActive,
  },
  {
    title: 'Categories',
    route: 'categories',
    icon: category, 
    iconActive: categoryActive,
  },
  {
    title: 'Offers',
    route: 'offers',
    icon: offers,
    iconActive: offersActive,
  },
  {
    title: 'Product',
    route: 'product',
    icon: Product,
    iconActive: ProductActive,
  },
  {
    title: 'Suggestion',
    route: 'suggestion',
    icon: Suggestion,
    iconActive: SuggestionActive,
  },
  {
    title: 'Complaints',
    route: 'complaints',
    icon: Product,
    iconActive: ProductActive,
  },
  {
    title: 'Comment',
    route: 'comment',
    icon: Comment,
    iconActive: CommentActive,
  },
  {
    title: 'Ecommerce',
    route: 'ecommerce',
    icon: ecommerce,
    iconActive: ecommerceActive,
  },
  {
    title: 'MediaHub',
    route: 'media-hub',
    icon: mediahub,
    iconActive: mediahubActive,
  },
  {
    title: 'Deals',
    route: 'deals',
    icon: deals,
    iconActive: dealsActive,
  },
  {
    title: 'Coupon Suggestion',
    route: 'coupon-suggestion',
    icon: deals,
    iconActive: dealsActive,
  },
  {
    title: 'AdPlacement',
    route: 'ad-placement',
    icon: adplacement,
    iconActive: adplacementActive,
  },
  {
    title: 'Subscriptions',
    route: 'subscription',
    icon: subscription,
    iconActive: subscriptionActive,
  },
  {
    title: 'Users',
    route: 'users',
    icon: user,
    iconActive: userActive,
  },
  {
    title: 'Help',
    route: 'help',
    icon: help,
    iconActive: helpActive,
  },
  {
    title: 'Reports',
    route: 'reports',
    icon: reports,
    iconActive: reportsActive,
  },
  {
    title: 'App Settings',
    route: 'app-settings',
    icon: appsettings,
    iconActive: appsettingsActive,
  },
  {
    title: 'Cashback Program',
    route: 'cashback-program',
    icon: Cashback,
    iconActive: CashbackActive,
  },
  {
    title: 'Contact US',
    route: 'contact-us',
    icon: ContactUs,
    iconActive: ContactUsActive,
  },
  {
    title: 'Reported Coupons',
    route: 'reported-coupons',
    icon: ReportedCoupons,
    iconActive: ReportedCouponsActive,
  },
  {
    title: 'Ranking',
    route: 'ranking',
    icon: Ranking,
    iconActive: RankingActive,
  },
  {
    title: 'Challenge',
    route: 'challenge',
    icon: Challenge,
    iconActive: ChallengeActive,
  },
]     
