<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
      @click="logout(item)"
    >
    <div class="mr-1 lg-d-none"><img :src="isActive ? item.iconActive :item.icon" /></div>
    <div class="mr-1 d-xl-none"><img :src="isActive ? item.icon :item.icon" /></div>
    <!-- <div class="mr-1 "><img :src="isActive ? item.icon :item.icon" /></div> -->
      <!-- <v-icon>{{item.icon}}</v-icon> -->
      <!-- <feather-icon :icon="item.icon || 'CircleIcon'" /> -->
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<style>
@media only screen and (max-width: 1200px) { 
  .lg-d-none {
    display: none;
  } 
}
</style>
<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
  },
  data(){
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
    }
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods:{    
    logout(data) {
      if(data.title == "Logout"){
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

        localStorage.removeItem('application');
        localStorage.removeItem('applications');
        localStorage.removeItem('userData')

        this.$ability.update(initialAbility)

      //reset all store

        this.$http.post(`${this.baseURL}/logout/index`,).then((res)=>{
      console.log(res.data,"data")
      })
      setTimeout(() => {
        this.$store.commit('clearAllState');
      }, 200);
        this.$router.push({ name: 'auth-login' })
      }
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()
    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },

}
</script>
